<template>
  <div class="login-form">
    <form  v-grid="1" @submit.prevent="submit">
       <iField 
          label="Email"
          name="email"
          field="iEmailField" 
          v-model="email" 
          :error="error.email"
          @input="error = {}"
          />
       <iField 
          label="Password"
          field="iPasswordField" 
          v-model="password" 
          :error="error.password"
          @input="error = {}"
          />
      <div class="error" v-if="this.error.password">{{this.error.password}}</div>
      <iButton type="submit" label="Login"/>
    </form>
  </div>
</template>

<script>

export default {
  name: 'LoginForm',
  data() {
    return {
      email: "",
      password: "",
      error: {}
    }
  },
  methods: {
    submit() {
      const data = { email: this.email, password: this.password };
      this.$api
      .post('/app/login', data).then(
        res => {
          if (res.data.access_token) {
            this.$store.commit('logIn', { ...res.data })
            if (this.$route.query.nextUrl) 
              this.$router.push(this.$route.query.nextUrl);
            else
              this.$router.push('/');
          } else {
            this.error = res.data;
          }
        },
        err => { 
          this.error = err.response.data.title;
        }
      )
    }
  },
};
</script>

<style scoped lang="scss">
.login-form {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

form {
  width: 300px;
  border: 1px solid black;
  background-color: #fff;
  padding: 15px;
}
.error {
  color: red;
}
</style>
